import {FabricJSEditor} from "fabricjs-react";
import {useHistoryEvents, useHistoryEventsDispatch} from "../context/ActionsHistoryContext.tsx";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import {getRouletteConfiguration, RouletteConfigurationType, RouletteType} from "../data/RouletteConfiguration.ts";
import ROULETTE_TRANSLATIONS from "../defined.ts";

type ActionButtonsProps = {
    editor: FabricJSEditor | undefined,
    rouletteType: RouletteType
}
export default function ActionButtons(props: ActionButtonsProps) {

    const {editor, rouletteType} = props;
    const globalData = useGlobal();
    const globalDataDispatch = useGlobalDispatch();
    // const historyEvents = useHistoryEvents();
    const historyEventsDispatch = useHistoryEventsDispatch();

    const configuration = getRouletteConfiguration(rouletteType)
    const handleUndo = () => {
        historyEventsDispatch({
            type: "undo",
            payload: {
                editor: editor,
                globalData: globalData,
                globalDataDispatch: globalDataDispatch,
                configuration: configuration
            }
        });
    }
    const handleReset = () => {
        historyEventsDispatch({
            type: "clear",
            payload: {
                editor: editor,
                globalData: globalData,
                globalDataDispatch: globalDataDispatch,
                configuration: configuration
            }
        });
    }

    return (
        <div className="ActionButtons">
            <button className="ActionButtons__button" onClick={handleUndo}>{ROULETTE_TRANSLATIONS.UNDO}</button>
            <button className="ActionButtons__button" onClick={handleReset}>{ROULETTE_TRANSLATIONS.CLEAR_CHIPS}</button>
        </div>
    )
}
