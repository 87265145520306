export type ROULETTE_TRANSLATIONS_TYPE = {
    TOTAL_BET: string,
    ROULETTE_TYPE: string,
    TYPE_NAMES: {
        european: string,
        american: string,
        triple: string,
    },
    GAME_OPTION: string,
    GAME_OPTIONS: {
        SINGLE: string,
        SIMULATION: string,
        LIVE: string,
        ONLINE_DEALER: string,
        ONLINE: string,
        TARGET_STOP: string,
    }
    GAME_LENGTH: string,
    GAME_LENGTHS: {
        HOUR_1: string,
        HOUR_2: string,
        HOUR_3: string,
        HOUR_4: string,
    },
    TARGET_STOP: {
        HOW_IT_WORKS: string,
        HOW_IT_WORKS_DESCRIPTION: string,
        BANKROLL: {
            START: string,
            TARGET: string,
            STOP: string,
        }
    },
    SPIN: string,
    CALCULATE: string,
    PLAYING_STRATEGY: string,
    PLAYING_STRATEGY_FOR: string,
    PLAYING_STRATEGY_FOR_MULTI: string,
    ON_EACH_SPIN: string,
    CHANCE_OF_PROFIT: string,
    CHANCE_OF_LOSING: string,
    EXPECTED_VALUE: string,
    EXPECTED_LOSE: string,
    CHANCE_ABOUT_PROFIT: string,
    PROBABILITY_SUCCESS: string,
    PROBABILITY_FAILURE: string,
    ERRORS: {
        PLACE_COINS: string,
        STARTING_POSITIVE: string,
        TARGET_POSITIVE: string,
        STOP_POSITIVE: string,
        TOTAL_BET_BIGGER_THAN_BANKROLL: string,
        STOP_LESS_THAN_BANKROLL: string,
        START_LESS_THAN_TARGET_BANKROLL: string,
    },
    TOTAL_PROFIT: string,
    TOTAL_LOSS: string,
    WINNING_NUMBER: string,
    RESET_LOSS_PROFIT: string,
    UNDO: string,
    CLEAR_CHIPS: string,
    CURRENCY: string,
}

declare var ROULETTE_TRANSLATIONS: ROULETTE_TRANSLATIONS_TYPE;
export default ROULETTE_TRANSLATIONS;
