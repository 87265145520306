import {useEffect, useRef, useState} from "react";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import {FabricJSEditor} from "fabricjs-react";
import {
    AllBetsType,
    arraySum,
    getBets,
    getTotalBets,
    MathFactorial,
    multipleRandomFromWeightedArray
} from "../data/DataUtils.ts";
import {CheckIcon, ExpectedIcon, LoseIcon, WinIcon} from "../data/Icons.ts";
import {
    RouletteConfiguration,
    RouletteConfigurationType,
    RouletteFieldNumberType
} from "../data/RouletteConfiguration.ts";
import SelectTooltip from "./SelectTooltip.tsx";
import ROULETTE_TRANSLATIONS from "../defined.ts";



export type gameOptionTypes = 'single' | 'simulation' | 'live' | 'online_dealer' | 'online' | 'target_stop'
export type gameOptionsType = {
    [key: gameOptionTypes]: {betsPerHour: number, label: string}
}

const simulationGameOptionKey = 'simulation';
const targetStopGameOptionKey = 'target_stop';
const gameOptions: gameOptionsType = {
    single: {betsPerHour: 0, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.SINGLE},
    [simulationGameOptionKey]: {betsPerHour: 0, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.SIMULATION},
    live: {betsPerHour: 30, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.LIVE},
    online_dealer: {betsPerHour: 110, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.ONLINE_DEALER},
    online: {betsPerHour: 220, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.ONLINE},
    [targetStopGameOptionKey]: {betsPerHour: 0, label: ROULETTE_TRANSLATIONS.GAME_OPTIONS.TARGET_STOP}
}


type CalculatorProps = {
    configuration: RouletteConfigurationType
    editor: FabricJSEditor | undefined
}
export default function Calculator(props: CalculatorProps) {

    const globalData = useGlobal();
    const globalDataDispatch = useGlobalDispatch();
    const {editor, configuration} = props;
    const totalFields = configuration.numbers.length;

    const [possibleOutcomes, setPossibleOutcomes] = useState(new Map());
    const [possibleOutcomesElements, setPossibleOutcomesElements] = useState([]);
    const [expectedValue, setExpectedValue] = useState('');
    const [betsPerHour, setBetsPerHour] = useState(0);
    const [gameHours, setGameHours] = useState(1);
    const [gameOption, setGameOption] = useState<gameOptionTypes>('single');
    const [winningNumber, setWinningNumber] = useState<RouletteFieldNumberType | null>(null);
    const [winningNumberHistory, setWinningNumberHistory] = useState<RouletteFieldNumberType[]>([]);

    const [isSimulation, setIsSimulation] = useState(false);
    const [simulationValue, setSimulationValue] = useState<number>(0);

    const [isTargetStopGameOption, setIsTargetStopGameOption] = useState<boolean>(false);
    const [targetStopStartBankroll, setTargetStopStartBankroll] = useState<number>(0);
    const [targetStopTargetBankroll, setTargetStopTargetBankroll] = useState<number>(0);
    const [targetStopStopBankroll, setTargetStopStopBankroll] = useState<number>(0);
    const [targetStopValidationError, setTargetStopValidationError] = useState<string>('');
    const targetStopCalculateButtonElement = useRef(null);


    useEffect(() => {
        if(possibleOutcomesElements.length){
            getPossibleOutcomesElements();
        }
    }, [globalData.currency]);

    // Function to calculate and display potential profits and expected value (EV)
    const getPossibleOutcomes = () => {
        const bets = getBets(editor)
        let ev = 0; // Initialize expected value

        for (let id in bets) {
            const betAmount = bets[id].value;
            const probabilityWin = bets[id].field.boardData.bet.length / totalFields;
            const probabilityLoss = 1 - probabilityWin;

            let profit = betAmount * bets[id].field.boardData.multiplier;
            let loss = -betAmount;

            // Calculate expected value for individual bets
            ev += (probabilityWin * profit) + (probabilityLoss * loss);

        }

        let possibilities = new Map();
        for(let i = 0; i < totalFields; i++) {
            let simulatedProfit = simulateNumberProfit(bets, i);
            let currentPossibility = 1;
            if(possibilities.has(simulatedProfit)){
                currentPossibility += possibilities.get(simulatedProfit);
            }

            possibilities.set(simulatedProfit, currentPossibility);
        }

        possibilities = new Map([...possibilities].sort((a, b) => {
            return a[0] - b[0];
        }))


        setPossibleOutcomes((prev) => {
            return possibilities;
        });

        console.clear();
        possibilities.forEach((possibility, value) => {
            let percent = (possibility / totalFields * 100).toFixed(2);
            if(value >= 0){
                console.log(`%c${percent}% chance of profiting ${globalData.currency}${value}`, 'color: #00ff00')
            }else{
                console.log(`%c${percent}% chance of losing ${globalData.currency}${Math.abs(value)}`, 'color: #ff0000')
            }
        })
        console.log(`%cExpected Value: ${globalData.currency}${ev.toFixed(2)}`, 'color: #00ffff')

        setExpectedValue((prev) => {
            return ev.toFixed(3);
        })

        return {possibilities, ev};
    }

    const getPossibleOutcomesElements = () => {
        // let possibleOutcomesElements = [];
        let {possibilities, ev} = getPossibleOutcomes();
        const betsCount = calculateGamesCount();


        if(betsCount === 1) //single bet
        {
            const newPossibleOutomesElements = [];
            possibilities.forEach((possibility, value) => {
                let percent = (possibility / totalFields * 100).toFixed(2);
                if(value >= 0){
                    newPossibleOutomesElements.push(
                        <li className="Calculator__result Calculator__result--win" key={value}><WinIcon/> {ROULETTE_TRANSLATIONS.CHANCE_OF_PROFIT.replace('{percent}', percent).replace('{value}', value).replace('$', globalData.currency)}</li>
                    );
                }else{
                    newPossibleOutomesElements.push(
                        <li className="Calculator__result Calculator__result--lose" key={value}><LoseIcon/> {ROULETTE_TRANSLATIONS.CHANCE_OF_LOSING.replace('{percent}', percent).replace('{value}', Math.abs(value).toString()).replace('$', globalData.currency)}</li>
                    );
                }
            })

            if(ev){
                newPossibleOutomesElements.push(
                    <li className="Calculator__result Calculator__result--expected" key={ev}><ExpectedIcon/> {ROULETTE_TRANSLATIONS.EXPECTED_VALUE.replace('{value}', Math.abs(ev * betsCount).toFixed(2)).replace('$', globalData.currency)}</li>
                );
            }

            setPossibleOutcomesElements(newPossibleOutomesElements);

        }else{ //strategy



            const simulate_session = (num_trials, outcomes, probabilities) => {
                const simulatedProfits = multipleRandomFromWeightedArray(outcomes, probabilities, num_trials)
                const totalProfit = arraySum(simulatedProfits);
                return totalProfit > 0;
            }

            const monte_carlo_simulation = (num_trials, num_simulations, outcomes, probabilities) => {
                let profitable = 0;
                for(let i = 0; i < num_simulations; i++){
                    if (simulate_session(num_trials, outcomes, probabilities)){
                        profitable++;
                    }
                }
                return profitable / num_simulations;
            }
            /*
             import numpy as np

             # Function to simulate a single session
             def simulate_session(num_trials, outcomes, probabilities):
                 results = np.random.choice(outcomes, size=num_trials, p=probabilities)
                 total_profit = np.sum(results)
                 return total_profit > 0

             # Generalized Monte Carlo simulation
             def monte_carlo_simulation(num_trials, num_simulations, outcomes, probabilities):
                 profitable_sessions = sum(simulate_session(num_trials, outcomes, probabilities) for _ in range(num_simulations))
                 prob_profitable = profitable_sessions / num_simulations
                 return prob_profitable

             # Example usage
             if __name__ == "__main__":
                 # Define the betting outcomes and their probabilities
                 outcomes = [-30, 330]
                 probabilities = [34/37, 3/37]

                 # Simulation parameters
                 num_trials = 110
                 num_simulations = 100000

                 # Run the Monte Carlo simulation
                 prob_profitable = monte_carlo_simulation(num_trials, num_simulations, outcomes, probabilities)

                 print(prob_profitable)


     */
            const newPossibleOutomesElements = [];

            if(ev){
                newPossibleOutomesElements.push(
                    <li className="Calculator__result Calculator__result--lose" key={'strategyExpectedValue'}><LoseIcon/> {ROULETTE_TRANSLATIONS.EXPECTED_LOSE.replace('{value}', Math.abs(ev * betsCount).toFixed(2)).replace('$', globalData.currency)}</li>
                );
            }


            let profitChancePercent = monte_carlo_simulation(betsCount, 100000, [...possibilities.keys()], [...possibilities.values()])


            newPossibleOutomesElements.push(
                <li className="Calculator__result Calculator__result--win" key={'strategyProfitPercent'}><WinIcon/> {ROULETTE_TRANSLATIONS.CHANCE_ABOUT_PROFIT.replace('{percent}', (profitChancePercent*100).toFixed(2))}</li>
            );

            setPossibleOutcomesElements(newPossibleOutomesElements);
        }
    }

    const simulateNumberProfit = (bets: AllBetsType, number: number) => {
        let profit = 0;
        for (let id in bets) {
            const betAmount = bets[id].value;
            let isWinning = bets[id].field.boardData.bet.indexOf(number) !== -1;
            if(!isWinning){
                profit -= betAmount;
                continue;
            }
            profit += betAmount * bets[id].field.boardData.multiplier;
        }

        return profit;
    }

    const handleGameOptionChange = (ev) => {

        let value:gameOptionTypes = ev.target.value;
        setIsSimulation(value === simulationGameOptionKey);
        setIsTargetStopGameOption(value === targetStopGameOptionKey);
        setGameOption(value);
        setBetsPerHour(gameOptions[value].betsPerHour)
        if(possibleOutcomes){
            resetOutcomes();
        }
    }
    const handleGameLengthChange = (ev) => {
        setGameHours(parseInt(ev.target.value));
        if(possibleOutcomes){
            resetOutcomes();
        }
    }

    const resetOutcomes = () => {
        setPossibleOutcomes(new Map());
        setPossibleOutcomesElements([]);
        // setExpectedValue('');
    }

    const calculateGamesCount = () => {
        if(betsPerHour === 0) return 1;
        return gameHours * betsPerHour;
    }

    const handleRouletteTypeChange = (ev) => {
        globalDataDispatch({
            type: 'setRouletteType',
            payload: ev.target.value
        })
    }

    const simulateSpin = (ev) => {
        const bets = getBets(editor);
        let winningNumber = configuration.numbers[Math.floor(Math.random()*configuration.numbers.length)];
        let simulatedProfit = simulateNumberProfit(bets, winningNumber.number);
        globalDataDispatch({
            type: 'setWinningNumber',
            payload: winningNumber
        });
        setWinningNumber(winningNumber);
        setSimulationValue((prev) => {
            return prev+simulatedProfit
        });
        setWinningNumberHistory((prev) => {
            return [winningNumber, ...prev]
        })

    }

    const resetSimulation = () => {
        setSimulationValue(0);
        setWinningNumber(null);
        setWinningNumberHistory([]);
        globalDataDispatch({
            type: 'setWinningNumber',
            payload: null
        });
    }

    const getTargetStopPossibleOutcomesElements = () => {
        if(!validateTargetStopValuesValid()){
            return false;
        }


        const simulate_session_gamblers_ruin = (starting_bankroll, target_bankroll, stop_loss, outcomes, probabilities) => {
            let current_bankroll = starting_bankroll;
            /*
            while current_bankroll > stop_loss and current_bankroll < target_bankroll:
                outcome = np.random.choice(outcomes, p=probabilities)
                current_bankroll += outcome

            return current_bankroll >= target_bankroll
            *
            * */
            while(current_bankroll > stop_loss && current_bankroll < target_bankroll){
                let outcome = arraySum(multipleRandomFromWeightedArray(outcomes, probabilities, 1));
                current_bankroll += outcome;
            }
            return current_bankroll >= target_bankroll;
        }

        const monte_carlo_simulation_gamblers_ruin = (starting_bankroll, target_bankroll, stop_loss, outcomes, probabilities, num_simulations) => {
            /*
            *
            success_count = 0

            for _ in range(num_simulations):
                if simulate_session_gamblers_ruin(starting_bankroll, target_bankroll, stop_loss, outcomes, probabilities):
                    success_count += 1

            probability_of_success = success_count / num_simulations
            probability_of_failure = 1 - probability_of_success

            return probability_of_success, probability_of_failure
            *
            * */

            let success_count = 0;
            for(let i = 0; i < num_simulations; i++){
                if (simulate_session_gamblers_ruin(starting_bankroll, target_bankroll, stop_loss, outcomes, probabilities)){
                    success_count++;
                }
            }

            let probability_of_success = success_count / num_simulations;
            let probability_of_failure = 1 - probability_of_success;

            return probability_of_success;
        }


/*

# Example usage
if __name__ == "__main__":
    outcomes = []  # Example outcomes
    probabilities = []  # Example probabilities

   # Simulation parameters (these should be defined by the user)
    starting_bankroll = 0  # Example: 500
    target_bankroll = 0  # Example: 800
    stop_loss = 0  # Example: 0
    num_simulations = 100000  # Typically a large number for Monte Carlo simulations

    try:
        probability_of_success, probability_of_failure = monte_carlo_simulation_gamblers_ruin(
            starting_bankroll, target_bankroll, stop_loss, outcomes, probabilities, num_simulations
        )
        # Print results as percentages
        print(f"Probability of Success: {probability_of_success * 100:.2f}%")
        print(f"Probability of Failure: {probability_of_failure * 100:.2f}%")
    except ValueError as e:
        print(e)

        *
        * */

        if(targetStopCalculateButtonElement.current){
            targetStopCalculateButtonElement.current.classList.add('loading');
            targetStopCalculateButtonElement.current.setAttribute('disabled', true);
        }

        setTimeout(() => {
            let {possibilities, ev} = getPossibleOutcomes();
            let probability_of_success = monte_carlo_simulation_gamblers_ruin(
                targetStopStartBankroll,
                targetStopTargetBankroll,
                targetStopStopBankroll,
                [...possibilities.keys()],
                [...possibilities.values()],
                20000
            )
            let probability_of_failure = 1 - probability_of_success;



            const newPossibleOutomesElements = [];
            newPossibleOutomesElements.push(
                <li className="Calculator__result Calculator__result--win" key={'targetStopSuccessChance'}><WinIcon/> {ROULETTE_TRANSLATIONS.PROBABILITY_SUCCESS.replace('{percent}', (probability_of_success*100).toFixed(2))}</li>
            );
            newPossibleOutomesElements.push(
                <li className="Calculator__result Calculator__result--lose" key={'targetStopLoseChance'}><LoseIcon/> {ROULETTE_TRANSLATIONS.PROBABILITY_SUCCESS.replace('{percent}', Math.abs(probability_of_failure*100).toFixed(2))}</li>
            );
            setPossibleOutcomesElements(newPossibleOutomesElements);

            if(targetStopCalculateButtonElement.current){
                targetStopCalculateButtonElement.current.classList.remove('loading');
                targetStopCalculateButtonElement.current.removeAttribute('disabled');
            }
        }, 10)
    }
    const validateTargetStopValuesValid = () => {

        if(globalData.totalBet <= 0){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.PLACE_COINS)
            return false;
        }

        /*
        *     if starting_bankroll <= 0:
        raise ValueError("Starting Bankroll must be a positive number.")
        * */
        if(targetStopStartBankroll <= 0){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.STARTING_POSITIVE)
            return false;
        }

        /*
        *     if target_bankroll <= 0:
        raise ValueError("Target Bankroll must be a positive number.")
        * */
        if(targetStopTargetBankroll <= 0){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.TARGET_POSITIVE)
            return false;
        }

        /*    if stop_loss < 0:
        raise ValueError("Stop Loss must be zero or a positive number.")
        */
        if(targetStopStopBankroll < 0){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.STOP_POSITIVE)
            return false;
        }


        if(globalData.totalBet > targetStopStartBankroll){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.TOTAL_BET_BIGGER_THAN_BANKROLL)
            return false;
        }

        /*
        *     if stop_loss >= starting_bankroll:
        raise ValueError("Stop Loss must be less than Starting Bankroll.")
        * */
        if(targetStopStopBankroll >= targetStopStartBankroll){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.STOP_LESS_THAN_BANKROLL)
            return false;
        }

        /*if starting_bankroll >= target_bankroll:
        raise ValueError("Starting Bankroll must be less than Target Bankroll.")*/
        if(targetStopStartBankroll >= targetStopTargetBankroll){
            setTargetStopValidationError(ROULETTE_TRANSLATIONS.ERRORS.START_LESS_THAN_TARGET_BANKROLL)
            return false;
        }

        setTargetStopValidationError('');
        return true;
    }

    const handleTargetStopSettingsChange = (ev) => {
        let value = parseFloat(ev.target.value);
        switch (ev.target.name) {
            case 'start_bankroll': {
                setTargetStopStartBankroll(value);
                break;
            }
            case 'target_bankroll': {
                setTargetStopTargetBankroll(value);

                break;
            }
            case 'stop_bankroll': {
                setTargetStopStopBankroll(value);

                break;
            }
        }
        setTargetStopValidationError('');
    }

    let rouletteSelect = [];
    for(let type in RouletteConfiguration){
        rouletteSelect.push(
            <option key={type} value={type}>{RouletteConfiguration[type].name}</option>
        )
    }


    const gameOptionElements = [];
    for(let gameOptionKey in gameOptions){
        gameOptionElements.push(
            <option key={gameOptionKey} value={gameOptionKey}>{gameOptions[gameOptionKey].label}</option>
        )
    }

    return (
        <div className="Calculator">

            <div className="Calculator__box">
                <label htmlFor="roulette_select" className="Calculator__select-label">{ROULETTE_TRANSLATIONS.ROULETTE_TYPE}</label>

                <select id="roulette_select" className="Calculator__select Calculator__select--mb0" value={globalData.rouletteType} onChange={handleRouletteTypeChange}>
                    {rouletteSelect}
                </select>
            </div>

            <div className="Calculator__box Calculator__box--flex">
                <span className="Calculator__title">{ROULETTE_TRANSLATIONS.TOTAL_BET}</span>
                <span className={globalData.currency != '₹' ? "Calculator__total-bet" : "Calculator__total-bet Calculator__total-bet--inter"}>{globalData.currency + globalData.totalBet}</span>
            </div>

            <div className="Calculator__box">

                <label htmlFor="game_option" className="Calculator__select-label">
                    {ROULETTE_TRANSLATIONS.GAME_OPTION}
                </label>
                <select id="game_option" className="Calculator__select" value={gameOption} onChange={handleGameOptionChange}>
                    {gameOptionElements}
                </select>


                {betsPerHour > 0 &&
					<>
						<label htmlFor="game_length" className="Calculator__select-label">{ROULETTE_TRANSLATIONS.GAME_LENGTH}</label>
						<select id="game_length" className="Calculator__select" value={gameHours} onChange={handleGameLengthChange}>
							<option value="1">{ROULETTE_TRANSLATIONS.GAME_LENGTHS.HOUR_1}</option>
							<option value="2">{ROULETTE_TRANSLATIONS.GAME_LENGTHS.HOUR_2}</option>
							<option value="3">{ROULETTE_TRANSLATIONS.GAME_LENGTHS.HOUR_3}</option>
							<option value="4">{ROULETTE_TRANSLATIONS.GAME_LENGTHS.HOUR_4}</option>
						</select>
					</>
                }

                {isTargetStopGameOption &&
					<>
						<SelectTooltip
                            label={ROULETTE_TRANSLATIONS.TARGET_STOP.HOW_IT_WORKS}
                            text={ROULETTE_TRANSLATIONS.TARGET_STOP.HOW_IT_WORKS_DESCRIPTION.replaceAll('$', globalData.currency)}
                        />

						<label htmlFor="game_option" className="Calculator__select-label">{ROULETTE_TRANSLATIONS.TARGET_STOP.BANKROLL.START}</label>
						<div className="Calculator__input--suffix" data-suffix={globalData.currency}>
							<input type="number" min={0} name="start_bankroll" className="Calculator__input" value={targetStopStartBankroll} onChange={handleTargetStopSettingsChange}/>
						</div>

						<label htmlFor="game_option" className="Calculator__select-label">{ROULETTE_TRANSLATIONS.TARGET_STOP.BANKROLL.TARGET}</label>
						<div className="Calculator__input--suffix" data-suffix={globalData.currency}>
							<input type="number" min={0} name="target_bankroll" className="Calculator__input" value={targetStopTargetBankroll} onChange={handleTargetStopSettingsChange}/>
						</div>

						<label htmlFor="game_option" className="Calculator__select-label">{ROULETTE_TRANSLATIONS.TARGET_STOP.BANKROLL.STOP}</label>
						<div className="Calculator__input--suffix" data-suffix={globalData.currency}>
							<input type="number" min={0} name="stop_bankroll" className="Calculator__input" value={targetStopStopBankroll} onChange={handleTargetStopSettingsChange}/>
						</div>
					</>
                }


                {isSimulation &&
                    <button className="Calculator__button" type="button" onClick={simulateSpin}>
                        {ROULETTE_TRANSLATIONS.SPIN} <CheckIcon/>
                    </button>
                }

                {isTargetStopGameOption &&
					<>
                        {!!targetStopValidationError &&
                            <span className="Calculator__error-notice">{targetStopValidationError}</span>
                        }
						<button className="Calculator__button" ref={targetStopCalculateButtonElement} type="button" onClick={getTargetStopPossibleOutcomesElements}>
                            {ROULETTE_TRANSLATIONS.CALCULATE} <CheckIcon/>
						</button>
					</>

                }

                {(!isSimulation && !isTargetStopGameOption) &&
					<button className="Calculator__button" type="button" onClick={getPossibleOutcomesElements}>
                        {ROULETTE_TRANSLATIONS.CALCULATE} <CheckIcon/>
					</button>
                }


            </div>

            {!!possibleOutcomesElements.length &&
				<div className="Calculator__box">

                    {isTargetStopGameOption ?

                        <span className="Calculator__title">{ROULETTE_TRANSLATIONS.PLAYING_STRATEGY}</span>
                        :
                        <>
                        {betsPerHour > 0 ?
                                <span className="Calculator__title">
                                    {gameHours > 1 ?
                                        ROULETTE_TRANSLATIONS.PLAYING_STRATEGY_FOR_MULTI.replace('{gameHours}', gameHours.toString())
                                    :
                                        ROULETTE_TRANSLATIONS.PLAYING_STRATEGY_FOR.replace('{gameHours}', gameHours.toString())
                                    }
                                </span>
                                :
                                <span className="Calculator__title">{ROULETTE_TRANSLATIONS.ON_EACH_SPIN}</span>
                            }
                        </>
                    }


					<ul className="Calculator__results">
                        {possibleOutcomesElements}
					</ul>
				</div>
            }

            {isSimulation && winningNumber !== null &&
				<div className="Calculator__box">
					<span className="Calculator__title">{ROULETTE_TRANSLATIONS.WINNING_NUMBER} {winningNumber.label} <span
						style={{color: winningNumber.color}}>{winningNumber.color}</span></span>
					<div>
                        <span className="Calculator__title">
                           {simulationValue >= 0 ?
                               <span>{ROULETTE_TRANSLATIONS.TOTAL_PROFIT} <span
                                   style={{color: 'green'}}>{globalData.currency}{simulationValue}</span></span>
                               :
                               <span>{ROULETTE_TRANSLATIONS.TOTAL_LOSS} <span
                                   style={{color: 'red'}}>{globalData.currency}{Math.abs(simulationValue)}</span></span>
                           }
                        </span>
					</div>

                    {winningNumberHistory.length > 0 &&
                        <div className="Calculator__winning-numbers">
                            {winningNumberHistory.map((winningNumberItem, index) => {
                                return (
                                    <div className="Calculator__winning-numbers__item" style={{background: winningNumberItem.color}} key={index}>{winningNumberItem.label}</div>
                                )
                            })}
                        </div>
                    }

					<button className="Calculator__button Calculator__button--secondary" style={{marginTop: '28px'}} onClick={resetSimulation}>
                        {ROULETTE_TRANSLATIONS.RESET_LOSS_PROFIT}
					</button>

				</div>
            }


        </div>
    )
}
