import {FabricJSEditor} from "fabricjs-react";
import {useHistoryEvents, useHistoryEventsDispatch} from "../context/ActionsHistoryContext.tsx";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import {getRouletteConfiguration, RouletteConfigurationType, RouletteType} from "../data/RouletteConfiguration.ts";
import ROULETTE_TRANSLATIONS from "../defined.ts";
import ROULETTE_CURRENCIES from "../variables/ROULETTE_CURRENCIES.ts";


export default function CurrencySwitch() {

    const globalData = useGlobal();
    const globalDataDispatch = useGlobalDispatch();

    const handleCurrencyChange = (ev) => {
        globalDataDispatch({
            type: 'setCurrency',
            payload: ev.target.value,
        })
    }

    if(typeof ROULETTE_CURRENCIES === 'undefined' || ROULETTE_CURRENCIES.length <= 1){
        return null;
    }

    return (
        <div className="CurrencySwitch">
            <label htmlFor="CurrencySwitch__label" className="CurrencySwitch__text">{ROULETTE_TRANSLATIONS.CURRENCY}:</label>
            <select id="CurrencySwitch__select" className="CurrencySwitch__select" onChange={handleCurrencyChange}>
                {ROULETTE_CURRENCIES.map((currency, index) => {
                    return (
                        <option value={currency.sign} key={index}>{currency.name} ({currency.sign})</option>
                    )
                })}

            </select>
        </div>
    )
}
